.goldRateHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    
    
} 
.goldRateHeader h3, 
.goldRateTitle h3{
    margin: 0;
    text-transform: uppercase;
    font-size: 25px;
}
.goldRateHeader img{
    width: 60px;
}
.goldRateTitle{
    padding: 30px 100px 0 100px;
    text-align: center;
}
.goldRateList {
    border: 1px solid #f2f2f2;
}
.goldRateList ul {
    list-style: none;
    padding: 30px;
    margin: 0;
}
.goldRateList li{
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.goldRateList .name{
    display: inline-block;
    background-color: #f2ba59;
    padding: 9px 18px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
}

.goldRateList .number{
    padding: 9px 18px 9px 20px;
    background-color: #f7f7f7;
    width: calc(100% - 119px);
}
