.shopHeader .tm-shop-productview{
    margin-top: 0;
}
.shopHeader .tm-shop-countview{
    margin-top: 0;
}
.niceSelect {
    width: 200px;
    height: 50px;
    border-radius: 3px;
    line-height: 48px;
    padding: 0 15px;
    padding-right: 35px;
    border-color: #ebebeb;
    font-size: 15px;
    color: #a9a9a9;
}
.niceSelect:focus {
    border-color: #f2ba59;
    box-shadow: none;
}
.niceSelect option {
    min-height: auto;
    line-height: 30px;
}
.niceSelect option:hover {
    background: #f2ba59;
    color: #fff;
}

