#slider{
    position: relative;
}
#slider .owl-nav {
    width: 100%;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#slider .owl-nav .owl-prev,
#slider .owl-nav .owl-next {
    background: rgba(0, 0, 0, .1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #000;
    font-size: xx-large;
    display: flex;
    align-items: center;
    justify-content: center;
}
#slider .owl-nav .owl-prev span, 
#slider .owl-nav .owl-next span{
    position: relative;
    top: -2px;
    left: -1px;
}